var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "electronicInvoice-centent"
  }, [_c('div', {
    staticClass: "electronicInvoice-title"
  }, [_c('div', [_c('span', {
    on: {
      "click": function click($event) {
        _vm.isShowDetails = false;
      }
    }
  }, [_vm._v("发票中心")]), _vm.isShowDetails ? _c('span', [_vm._v("/" + _vm._s(_vm.act_tab === 0 ? "已开票订单" : "未开票订单"))]) : _vm._e(), _vm.isShowDetails ? _c('span', [_vm._v("/发票详情")]) : _vm._e()]), _vm.isShowDetails ? _c('div', {
    staticClass: "rulers",
    on: {
      "click": function click($event) {
        _vm.isShowRuler = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://obs.pujian.com/frontend/app/user/wh.png"
    }
  }), _c('span', [_vm._v("开票规则")])]) : _vm._e()]), !_vm.isShowDetails ? _c('div', [_c('div', {
    staticClass: "electronicInvoice-tabs"
  }, [_c('div', {
    staticClass: "tabs-box"
  }, [_c('span', {
    class: [_vm.act_tab === 0 ? 'act_tab' : ''],
    on: {
      "click": function click($event) {
        return _vm.checkTab(0);
      }
    }
  }, [_vm._v("已开票订单")]), _c('span', {
    class: [_vm.act_tab === 1 ? 'act_tab' : ''],
    on: {
      "click": function click($event) {
        return _vm.checkTab(1);
      }
    }
  }, [_vm._v("未开票订单")])]), _c('div', {
    staticClass: "rulers",
    on: {
      "click": function click($event) {
        _vm.isShowRuler = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://obs.pujian.com/frontend/app/user/wh.png"
    }
  }), _c('span', [_vm._v("开票规则")])])]), _c('div', {
    staticClass: "electronicInvoice-search"
  }, [_c('div', {
    staticClass: "search-item"
  }, [_c('span', [_vm._v("订单搜索")]), _c('b-input', {
    attrs: {
      "placeholder": "请输入订单编号",
      "icon": "search",
      "icon-right": "close-circle",
      "icon-right-clickable": ""
    },
    on: {
      "icon-right-click": _vm.clearIconClick
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getInvoiceList.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search_val,
      callback: function callback($$v) {
        _vm.search_val = $$v;
      },
      expression: "search_val"
    }
  })], 1), _c('div', {
    staticClass: "search-item"
  }, [_c('span', [_vm._v("时间范围")]), _c('date-picker', {
    attrs: {
      "format": "YYYY-MM-DD",
      "disabled-date": _vm.disabledDate,
      "placeholder": "请选择日期",
      "range": ""
    },
    on: {
      "input": _vm.handleSelect,
      "clear": _vm.handleClear
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1)]), _c('div', {
    staticClass: "electronicInvoice-list"
  }, [_vm.infoList.length === 0 ? _c('div', {
    staticClass: "empty"
  }, [_vm._m(0)]) : _vm._e(), _vm._l(_vm.infoList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "electronicInvoice-list-item",
      on: {
        "click": function click($event) {
          return _vm.toDeatils(item);
        }
      }
    }, [_c('p', [_c('span', [_vm._v("订单时间：" + _vm._s(item.orderTime) + " ")]), _c('span', {
      staticClass: "oderNum"
    }, [_vm._v(_vm._s(item.orderNumber))])]), _c('p', [_vm.act_tab === 0 ? _c('span', [_vm._v(_vm._s(item.invoiceType == 1 ? "纸质专票" : item.invoiceType == 2 ? "纸质普票" : "电子增值税普通发票"))]) : _c('span'), _c('span', {
      staticClass: "orderMoney",
      style: {
        'color': _vm.act_tab === 0 ? '#3875F6' : '#333333'
      }
    }, [_vm._v("￥" + _vm._s(item.orderAmount)), _c('img', {
      attrs: {
        "src": _vm.r_icon
      }
    })])])]);
  })], 2), _vm.total > 0 ? _c('div', {
    staticClass: "pagination",
    staticStyle: {
      "width": "940px",
      "justify-content": "flex-end",
      "margin-left": "0px"
    }
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "total": _vm.total,
      "current": _vm.current,
      "per-page": _vm.perPage,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.current = $event;
      },
      "change": _vm.handleChange
    }
  })], 1) : _vm._e()]) : _c('electronicInvoiceDetails', {
    attrs: {
      "detailData": _vm.detailData
    },
    on: {
      "backInvoice": _vm.backInvoice
    }
  }), _c('b-modal', {
    attrs: {
      "scroll": "keep",
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "message-dialog"
        }, [_c('h4', {
          staticClass: "groupTitle"
        }, [_c('span', [_vm._v("开票规则")]), _c('button', {
          staticClass: "delete",
          attrs: {
            "type": "button"
          },
          on: {
            "click": props.close
          }
        })]), _c('div', {
          staticClass: "rulerContent"
        }, [_c('p', {
          staticClass: "ruler-title"
        }, [_vm._v("一、电子发票开票方式")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("1、【按单开具】")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("(1)销售单：订单配送完成后，48小时内按单自动开具蓝字电子发票。")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("(2)退货单：普健收到退货入库后，48小时内按单自动开具红字电子发票。")]), _c('p', {
          staticClass: "name pt"
        }, [_vm._v("2、【周期开票】")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("(1)开票日期：可联系业务员设定，不做要求的统一为次月1-5日汇总开具上月发票。")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("注：若设定日期>当月最后一天日期的，实际开票日期为当月最后一天。")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("(2)开票内容：开票日期汇总 开票日前所有未开发票单据并自动开具电子发票。")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("销售单汇总开具蓝字电子发票，退货汇总开具红字电子发票。")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("注：如遇税务系统问题，开具发票将自动顺延。")]), _c('p', {
          staticClass: "ruler-title pt"
        }, [_vm._v("二、开票金额")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("发票开具金额为您实际应支付金额，不包括在线支付享受的支付优惠金额。")]), _c('p', {
          staticClass: "ruler-title pt"
        }, [_vm._v("三、发票购买单位")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("为商品购买单位名称，如有总分支机构等原因需要汇总开具的，请提供相关证明材料。")]), _c('p', {
          staticClass: "ruler-title pt"
        }, [_vm._v("四、发票补开换开")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("由于开票信息有误等原因需要补开、换开发票的，请联系业务员。")]), _c('p', {
          staticClass: "ruler-title pt"
        }, [_vm._v("五、如何查看发票")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("2个地方可以发票查看、分享、下载电子发票：")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("(1)我的--我的订单")]), _c('p', {
          staticClass: "name"
        }, [_vm._v("(2)我的--查看发票")])])])];
      }
    }]),
    model: {
      value: _vm.isShowRuler,
      callback: function callback($$v) {
        _vm.isShowRuler = $$v;
      },
      expression: "isShowRuler"
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "empty-list"
  }, [_c('img', {
    attrs: {
      "src": require("../enshrine/images/pic_empty.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("暂无数据")])]);
}];
export { render, staticRenderFns };